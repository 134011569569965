/* Undo openlayer's default styling: */
.ol-control {
  background-color: transparent
}
.ol-control:hover {
  background-color: transparent;
}
.ol-control button {
  margin: 0;
}

._2k8nNcoGPGdN7SDnexKBAG,
.g_vZFzrl2K08jRtbp5Pxs {
  --container-top: 10px;
  --container-right: 10px;
  --button-size: 22px;
  --button-size: 1.375rem;
  --button-margin-bottom: 5px;

  position: absolute;
  top: var(--container-top);
  right: var(--container-right)
}

._2k8nNcoGPGdN7SDnexKBAG button, .g_vZFzrl2K08jRtbp5Pxs button {
  width: 80px;
  height: 22px;
  border: 1px solid rgb(136, 136, 153);
  border-radius: 2px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgb(136, 136, 153);
  opacity: 0.75;
  margin-bottom: 10px;
  font-weight: bold;
  background-color: #fff;
  font-size: 9.6px;
  font-size: 0.6rem;
  text-transform: uppercase
}

._2k8nNcoGPGdN7SDnexKBAG button:hover, .g_vZFzrl2K08jRtbp5Pxs button:hover {
  opacity: 1;
  background-color: white;
}

.g_vZFzrl2K08jRtbp5Pxs button::after {
  content: 'ZOOM';
  margin-left: 4px;
  margin-left: 0.25rem;
}

/* This is needed so that the "reset" button lines up below the zoom in/out buttons: */
.WpT862pBU2Us81_NAppnA {
  top: calc(var(--container-top) + var(--button-size) * 2 + var(--button-margin-bottom) * 2);
  right: var(--container-right);
}

._1myKUJUeHtseMDKMOCbv2M {
  top: calc(var(--container-top) + var(--button-size) * 2 + var(--button-margin-bottom) * 2);
  right: var(--container-right);
  margin-top: 10px;
}
