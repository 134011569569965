._1B1mi_KyW3GkuNAxHw8RXb {
  top: 0;
  -webkit-transform: translateY(calc(-50% + var(--translation)));
          transform: translateY(calc(-50% + var(--translation)));
}

._1CMt1WkgoxBK_AUzZkH0G5 {
  left: 0;
  -webkit-transform: translateX(calc(-50% + var(--translation))) rotate(var(--rotation));
          transform: translateX(calc(-50% + var(--translation))) rotate(var(--rotation));
}

.mm6mA_ew2SAjjHuni6zLi {
  background-color: #ccc;
}

._27yJt657Mud1hxvFdFHMOE {
  width: var(--width);
  height: var(--length);
  top: 0;
}

._1_8gvhhM5W6mZ-RAvdvY-K {
  width: var(--length);
  height: var(--width);
  right: 0;
}

.o1I5RDTJgiWVOw0FDfwuz {
  --rotation: -65deg;

  top: var(--label-separation);
  -webkit-transform-origin: right top;
          transform-origin: right top;
}

._2xJIgizaN4Etxb4FWt8JLf {
  --rotation: 0deg;

  top: var(--label-separation);
  -webkit-transform-origin: right top;
          transform-origin: right top;
}

.iuA_e1oGK4mHNTDezV1WZ {
  right: var(--label-separation);
}

._3oxWgmtNXsVSPmGT4rZaxH {
  left: 0;
  position: absolute;
  height: 0;
}

._3GQJJ_RaA2BdpwNROWpcMH {
  border: 2px #ccc dashed;
}

._3IYztXTP7_uVYeETg-wxwl {
  border: 2px #5065a8 dashed;
}

._2i-rbLcct0PDL0UACjPitD {
  position: absolute;
  left: 16px;
  left: 1rem;
  top: 16px;
  top: 1rem;
}

._3YNE4GWtEKonAehLAO4Fem {
  font-size: 0.8125rem;
  color: #5065a8;
  background-color: #fff;
}
