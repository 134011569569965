.NseBFcUHbQiqWymzKScwf {
  background-color: #ccc;
}

._2FOZqIl-tisN9b3oIDsKWq {
  height: var(--width);
  top: 0;
  left: 0;
  right: 0;
  -webkit-transform: translateY(var(--translation));
          transform: translateY(var(--translation));
}

._1ZjIbGNkW02ae1jC8BtP8T {
  width: var(--width);
  top: 0;
  bottom: 0;
  -webkit-transform: translateX(var(--translation));
          transform: translateX(var(--translation));
}
