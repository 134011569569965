._3A5p6A760zvYcc9og4s2Nl {
  position: absolute;

  --width: 1px;
  --scale-factor: 1;
  --translation: 0;
  --label-separation: 20px;
  --padding: 2px;
  --marker-top: calc(var(--label-separation) - var(--padding));
  --marker-right: calc(var(--label-separation) - var(--padding));
}

._1bJtm4d98ZSs4bYjU62wZx {
  background-color: var(--marker-color);
}

._32IrHkbwXxuzkCw016kLwQ {
  height: var(--width);
  top: 0;
  left: 0;
  right: 0;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  -webkit-transform: translateY(var(--translation)) scaleX(var(--scale-factor));
          transform: translateY(var(--translation)) scaleX(var(--scale-factor));
}

.WMkN5B2uCuSrii4w575f6 {
  width: var(--width);
  left: 0;
  bottom: 0;
  top: 0;
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: translateX(var(--translation)) scaleY(var(--scale-factor));
          transform: translateX(var(--translation)) scaleY(var(--scale-factor));
}

._16GC-mGVQPyH73tAUqXUQn {
  border: 5px solid var(--marker-color);
  padding: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
}

._3QmMu5ilD6rpwfJ-xtJJB4 {
  left: 0;
  top: var(--marker-top);
  -webkit-transform: translateX(calc(-50% + var(--translation)));
          transform: translateX(calc(-50% + var(--translation)));
}

._3MHxR_ADsDQ_ZyG_nfct0a {
  top: 0;
  right: var(--marker-right);
  -webkit-transform: translateY(calc(-50% + var(--translation)));
          transform: translateY(calc(-50% + var(--translation)));
}
