.c_cRE-3CdFVaTpyZrI_XL {
  width: var(--length);
  height: var(--width);
  right: 0;
  top: 0;
  -webkit-transform: translateY(var(--translation));
          transform: translateY(var(--translation));
}

._2TytXnbdkE5oXuXxUWli8v {
  width: var(--width);
  height: var(--length);
  left: 0;
  top: 0;
  -webkit-transform: translateX(var(--translation));
          transform: translateX(var(--translation));
}
