._6nAeuj050bhNMBdt7GsEF {
  --active-stroke-color: #fff;
  --active-stroke-width: 2.5;
}

._1Wbl4S7ro4SqfFyNCZbcCR {
  cursor: pointer
}

._1Wbl4S7ro4SqfFyNCZbcCR._9yC-TDoXpo-Zk2W3KRsny {
  stroke: rgb(50, 70, 100);
  stroke-width: var(--active-stroke-width);
  opacity: 1;
}

._1Wbl4S7ro4SqfFyNCZbcCR._1QrmrQ92-lqNTFHBIDP632 {
  opacity: 1;
}

._1Wbl4S7ro4SqfFyNCZbcCR:hover {
  stroke: var(--active-stroke-color);
  stroke-width: var(--active-stroke-width);
  opacity: 1;
}

._2iIGb4p3Nbl8LEgOaJgTEg {
  opacity: 0.2;
}

._1NKT1XuUkGQNielzjVy9uv {
  fill-opacity: 0;
}
